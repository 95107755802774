<template>
  <div>
    <main>
      <section id="prez">
        <b-container class="mt-sm-4 py-3">
          <b-row>
            <b-col class="text-right m-sm-0 mr-3 mt-3">
              <I18n />
            </b-col>
          </b-row>
          <b-row class="mx-0">
            <b-col
              class="mx-auto text-center"
              lg="8"
            >
              <h1 class="mb-3">
                <img
                  alt="Mobilizon"
                  height="45"
                  :src="`${$t('baseurl')}img/logo-mbz-purple.svg`"
                />
              </h1>
              <h2>
                <span
                  v-for="(tag, index) in $t('home.intro.tags')"
                  :key="index"
                >
                  {{ tag }}
                  <span v-if="index < $t('home.intro.tags').length - 1"> ⋅ </span>
                </span>
              </h2>
              <img
                alt=""
                class="my-4"
                :src="`${$t('baseurl')}img/rose.jpg`"
              />
              <b-alert
                show
                variant="info text-left"
                v-html="$t('warning')"
              />
            </b-col>
          </b-row>
          <b-row class="mx-0">
            <b-col
              lg="8"
              class="mx-auto"
            >
              <div
                id="jmz"
                v-html="$t('newvisitor.jmz', {
                  blog: $t('lang') === 'fr' ? $t('links.fr.blog') : $t('link.en.blog')
                })"
              ></div>
              <div
                id="demo"
                v-html="$t('newvisitor.demo')"
              ></div>

              <img
                alt=""
                class="d-block mx-auto my-4"
                :src="`${$t('baseurl')}img/fennetoile2.jpg`"
              />

              <div v-html="$t('newuser.title')"></div>

              <b-row class="mx-0">
                <b-col
                  class="alert alert-primary mt-3"
                  lg="5"
                  xl="4"
                  order-lg="2"
                >
                  <b-badge
                    variant="primary d-block py-1 mb-2"
                    v-html="`${$t('newuser.badge')}`"
                  />
                  <div
                    style="font-size: .93rem;"
                    v-html="$t('newuser.definition')"
                  ></div>
                </b-col>
                <b-col
                  class="pl-0"
                  lg="7"
                  xl="8"
                  order-lg="1"
                >
                  <div
                    id="selection"
                    v-html="$t('newuser.selection')"
                  ></div>
                </b-col>
              </b-row>

              <table class="table table-striped table-hover mt-4">
                <thead class="thead-light">
                  <tr>
                    <th
                      scope="col"
                      v-html="$t('column.country')"
                    ></th>
                    <th
                      scope="col"
                      v-html="$t('column.name')"
                    ></th>
                    <th
                      scope="col"
                      v-html="$t('column.hoster')"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(instance, index) in $t('selection')"
                    :key="index"
                  >
                    <td>
                      <span
                        :title="$te(`country.${instance[0]}`)
                          ? $t(`country.${instance[0]}`)
                          : ''"
                        v-html="$te(`country.${instance[0]}`)
                          ? $t(instance[0], '-F')
                          : '❓'"
                      ></span>
                    </td>
                    <td>
                      <a :href="instance[2]">
                        <b
                          class="text-info"
                          v-html="instance[1]"
                        ></b>
                      </a>
                      <a
                        :href="`${instance[2]}/about/instance`"
                        style="background: transparent !important"
                        :title="$t('column.about')"
                      >
                        <i class="fas fa-circle-info fa-xs"></i>
                        <span
                          class="sr-only"
                          v-html="$t('column.about')"
                        ></span>
                      </a>
                    </td>
                    <td>
                      <b
                        class="text-info"
                        v-html="instance[3]"
                      ></b>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                id="instances"
                v-html="$t('newuser.instances')"
              ></div>

              <img
                alt=""
                class="d-block mx-auto my-4"
                :src="`${$t('baseurl')}img/fennetoile1.jpg`"
              />

              <div
                id="help"
                v-html="$t('help.title')"
              ></div>

              <b-row class="mx-0 my-2">
                <b-col
                  class="list-group my-2"
                  md="6"
                >
                  <a
                    class="list-group-item"
                    :href="`${$t('link.jmz')}/faq`"
                  >
                    <i
                      :class="`${$t('icon.faq')
                      } fa-lg fa-shape-circle mr-3 float-left fa-white`"
                    ></i>
                    <span v-html="$t('help.faq')"></span>
                  </a>
                </b-col>
                <b-col
                  class="list-group my-2"
                  md="6"
                >
                  <a
                    class="list-group-item"
                    :href="$t('link.jmz-docs')"
                  >
                    <i
                      :class="`${$t('icon.doc')
                      } fa-lg fa-shape-circle mr-3 float-left fa-white`"
                    ></i>
                    <span v-html="$t('help.docs')"></span>
                  </a>
                </b-col>
                <b-col
                  class="list-group my-2"
                  md="6"
                >
                  <a
                    class="list-group-item"
                    :href="$t('link.contact')"
                  >
                    <i
                      :class="`${$t('icon.contact')
                      } fa-lg fa-shape-circle mr-3 float-left fa-white`"
                    ></i>
                    <span v-html="$t('help.contact')"></span>
                  </a>
                </b-col>
                <b-col
                  class="list-group my-2"
                  md="6"
                >
                  <a
                    class="list-group-item"
                    :href="$t('lang') === 'fr'
                      ? $t('link.fr.forum')
                      : $t('link.en.forum')"
                  >
                    <i
                      :class="`${$t('icon.colibri')
                      } fa-lg fa-shape-circle mr-3 float-left fa-white`"
                    ></i>
                    <span v-html="$t('help.forum')"></span>
                  </a>
                </b-col>
              </b-row>

              <img
                alt=""
                class="d-block mx-auto my-4"
                :src="`${$t('baseurl')}img/fennetoile3.jpg`"
              />

              <div
                id="frama"
                v-html="$t('lang') === 'fr'
                  ? $t('frama', $t('link.fr'))
                  : $t('frama', $t('link.en'))"
              ></div>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </main>
  </div>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';

export default {
  components: {
    I18n,
  },
};
</script>

<style lang="scss">
.violet { color: var(--frama); }
.orange { color: var(--soft); }

#fp-footer #f-footer {
  display: none !important;
}

/* Sections background */
html {
  background: var(--d1) !important;
}

#app {
  background: var(--d1);
  color: var(--s5);

  h1, h2, h3 {
    color: var(--s4);
  }

  h2 {
    background: var(--p2);
    padding: .5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    margin-top: 1rem;
    margin-bottom: .5rem;
  }

  p {
    &:last-child {
      margin: 0;
    }
  }

  main {
    .container {
      background: #fff;
      margin-bottom: 1.5rem;

      .table,
      .list-group {
        i.fas {
          color: var(--s3);
        }
      }

      a:not(.btn) {
        color: var(--s5);
        background: linear-gradient(to top, var(--p4) 2px, transparent 2px);

        &:hover,
        &:focus {
          color: var(--s6);
          background: linear-gradient(to top, var(--p4) 2px, var(--p1) 2px);
          text-decoration: none;
        }
      }

      .list-group .list-group-item {
        background: none;
        height: 100%;

        &:hover,
        &:focus {
          background: var(--p1);
        }
      }
    }

    ul:not(.list-inline) {
      list-style: url('../../public/img/bullet-o.png');
      padding-left:20px;
    }
  }

}

#f-i18n {
  ul.dropdown-menu.show {
    list-style: none;
    padding-left: 0;

    .dropdown-item {
      background: #fff;

      &:hover, &:focus {
        background: var(--p2);
      }
    }
  }
}
</style>
